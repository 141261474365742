import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import Spinner from "../commonComponents/Spinner";
import { useTranslation } from "react-i18next";
import "react-toggle/style.css";

import { EInvalidDataBehavior, EStepHook } from "../../interfaces";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  hideProcessingModal,
  showProcessingModal,
} from "../../store/reducers/commonComponents";
import {
  selectColErrors,
  selectRowsWithErrors,
  setBeforeFinishMessage,
} from "../../store/reducers/coredata";
import { createSnapshot } from "../../store/reducers/fields";
import { setRehydrationComplete } from "../../store/reducers/modals";
import {
  addEmptyRows,
  initializeForReview,
  removeRows,
  runRowHooks,
  addOrRemoveQueuedRows,
  SearchOpts,
  findAndReplace,
  runTransformDataChanges,
  search,
  processChanges,
} from "../../thunks/data_actions";
import {
  HotChange,
  updateFullData,
  buildEmptyRowFromMappedFieldInstances,
  FullDataWithMeta,
} from "../../util/data_actions";
import {
  handleColumnHooks,
  handleRowDeleteHooks,
  handleRowHooks,
  handleStepHook,
  handleBeforeFinish as onBeforeFinish,
  handleResults as onResults,
  handleCancelModal,
  handleCloseModal,
} from "../../thunks/parent_connection_handlers";
import { finalizeMapping } from "../../thunks/virtual_fields";
import { downloadExport } from "../../thunks/error_export";
import { useStepNavigation } from "../../thunks/step_navigation";
import { ReactComponent as ExportIcon } from "../../assets/export.svg";
import { Button } from "../commonComponents/Button";
import Text from "../commonComponents/Text";
import { useParentConnectionContext } from "../ParentConnectionContext";
import { useDataContext } from "../DataContext";
import { TransformDataSuccess } from "../../thunks/user_functions";
import { AlertModal } from "../AlertModal";
import HelpText from "../HelpText";
import TableWrapper from "../TableWrapper";
import { UpsellModal } from "../UpsellModal";
import ButtonDropdown from "../commonComponents/ButtonDropdown";
import StepContainer from "../commonComponents/StepContainer";
import FindAndReplaceModal from "./FindAndReplaceModal";
import { Alert } from "../commonComponents/Alert";
import { Card } from "../commonComponents/Card";
import { ReactComponent as CheckIcon } from "../../assets/check.svg";
import { ErrorFilterButton } from "./ErrorFilterButton";
import UserFunction from "../UserFunction";
import {
  runSavedSchemaBeforeFinish,
  runSavedSchemaStepHooks,
} from "../../thunks/hooks";
import { Search } from "react-bootstrap-icons";
import SearchInput from "./SearchInput";
import { flushSync } from "react-dom";
import { sanitizeHTML } from "../../util/sanitizeHTML";
import { processChangesInWorker, terminateWorker } from "../../workers/worker";
import { selectMappedFieldInstances } from "../../store/selectors";
import { useHiddenRows } from "./hooks/useHiddenRows";

interface IAlertModalState {
  show: boolean;
  action: "submit" | "back" | "cancel";
}

const DataReviewModal = () => {
  const connection = useParentConnectionContext();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    importerMode,
    invalidDataBehavior,
    rehydrateStage,
    rehydrationComplete,
    rowsWithErrors,
    selectedHandsonCell,
    stepSettings,
    colErrors,
    rowsToAdd,
    rowsToRemove,
    settings,
    beforeFinishMessage,
    submitConfirmationOverride,
    mappedFieldInstances,
  } = useAppSelector((state) => ({
    importerMode: state.settings.importerMode,
    invalidDataBehavior: state.settings.invalidDataBehavior,
    rehydrateStage: state.modals.rehydrateStage,
    rehydrationComplete: state.modals.rehydrationComplete,
    rowsWithErrors: selectRowsWithErrors(state.coredata),
    colErrors: selectColErrors(state),
    selectedHandsonCell: state.commonComponents.selectedHandsonCell,
    stepSettings: state.settings.reviewStep,
    rowsToAdd: state.coredata.rowsToAdd,
    rowsToRemove: state.coredata.rowsToDelete,
    settings: state.settings,
    beforeFinishMessage: state.coredata.beforeFinishMessage,
    submitConfirmationOverride: state.coredata.submitConfirmationOverride,
    mappedFieldInstances: selectMappedFieldInstances(state),
  }));

  const [isLoading, setIsLoading] = useState(true);
  const [isProcessingChanges, setIsProcessingChanges] = useState(false);
  const { fullData, setFullData } = useDataContext();
  const [hiddenSearchRows, setHiddenSearchRows] = useState<Set<number>>(
    new Set()
  );
  const lastQuery = useRef<string | null>(null);
  const [searchResults, setSearchResults] = useState<Map<
    string,
    string
  > | null>(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const isChangedByUserRef = useRef(false);
  const [showUpsellModal, setShowUpsellModal] = useState<boolean>(false);
  const [alertModal, setAlertModal] = useState<IAlertModalState>({
    show: false,
    action: "cancel",
  });
  const [showFindAndReplace, setShowFindAndReplace] = useState(false);
  const { canGoBack, goBack } = useStepNavigation();

  const {
    hiddenRows,
    hiddenRowsVersion,
    currentFilter,
    currentColumnIndexErrorFilter,
    showAllRows,
    showOnlyRowsWithErrors,
    showOnlySingleColumnErrors,
  } = useHiddenRows();

  const emptyRowCount = useMemo(() => {
    return fullData.reduce((count, row) => {
      const rowEmpty = row
        .slice(0, -1)
        .every((cell) => cell === "" || cell === undefined || cell === null);

      return rowEmpty ? count + 1 : count;
    }, 0);
  }, [fullData]);

  const renderAlertMessages = () => {
    const messages: React.ReactNode[] = [];

    if (beforeFinishMessage !== null) {
      messages.push(
        <Alert
          type="error"
          data-cy="step-error-message"
          key="step-error-message"
        >
          <div dangerouslySetInnerHTML={{ __html: beforeFinishMessage }} />
        </Alert>
      );
    }

    if (stepSettings.helpText) {
      messages.push(
        <HelpText
          content={stepSettings.helpText}
          className="mt-0"
          key="step-help-text"
        />
      );
    }

    return messages;
  };

  const [isUserFunctionOpen, setIsUserFunctionOpen] = useState(false);

  const initReview = useCallback(async () => {
    dispatch(createSnapshot("PREREVIEW"));

    await dispatch(runSavedSchemaStepHooks(EStepHook.REVIEW_STEP));
    await dispatch(handleStepHook(connection, EStepHook.REVIEW_STEP));

    // Add hidden and virtual fields.
    // These fields are only available in the Review Modal and can only be set via
    // row or column hooks
    let processedData = dispatch(finalizeMapping(fullData));

    processedData = await dispatch(
      initializeForReview(
        processedData,
        (...args) => dispatch(handleColumnHooks(connection, ...args)),
        (...args) => dispatch(handleRowHooks(connection, ...args))
      )
    );

    await dispatch(runSavedSchemaStepHooks(EStepHook.REVIEW_STEP_POST_HOOKS));
    await dispatch(
      handleStepHook(connection, EStepHook.REVIEW_STEP_POST_HOOKS)
    );
    setFullData(processedData);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rowsToAddOrRemove = rowsToAdd.length > 0 || rowsToRemove.length > 0;
  const shouldRehydrate =
    !rehydrationComplete && rehydrateStage === "DATA_REVIEW";

  useEffect(() => {
    async function init() {
      if (shouldRehydrate) {
        dispatch(setRehydrationComplete());
        setIsLoading(false);
      } else {
        await initReview();
      }
    }

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initReview, dispatch]);

  useEffect(() => {
    if (rowsToAddOrRemove && !isLoading) {
      const newData = dispatch(addOrRemoveQueuedRows(fullData));
      setFullData(newData);
    }
  }, [dispatch, fullData, setFullData, rowsToAddOrRemove, isLoading]);

  const downloadRichXLSX = (): void => {
    dispatch(downloadExport(fullData, false));
  };

  const downloadRichXLSXOnlyErrors = (): void => {
    dispatch(downloadExport(fullData, true));
  };

  const handleBeforeFinish = async (): Promise<boolean> => {
    const {
      savedSchemaHooks: { beforeFinishCallback },
    } = settings;
    if (beforeFinishCallback) {
      const savedSchemaResult = await dispatch(
        runSavedSchemaBeforeFinish(fullData)
      );
      if (savedSchemaResult && savedSchemaResult.cancel) {
        dispatch(setBeforeFinishMessage(savedSchemaResult.message));
        return false;
      }
    }

    const callbackResult = await dispatch(onBeforeFinish(connection, fullData));

    if (callbackResult && callbackResult.cancel) {
      dispatch(setBeforeFinishMessage(callbackResult.message));
      return false;
    } else {
      return true;
    }
  };

  const handleResults = async (): Promise<void> => {
    await dispatch(onResults(connection, fullData));
  };

  const onFinish = async (): Promise<void> => {
    dispatch(showProcessingModal());

    const beforeFinishSuccess = await handleBeforeFinish();

    if (beforeFinishSuccess) {
      terminateWorker();
      await handleResults();
      dispatch(hideProcessingModal());
      dispatch(handleCloseModal(connection));
    } else {
      dispatch(hideProcessingModal());
    }
  };

  // If the user is only showing rows with errors, but then fixes the last error,
  // we want to automatically go back to showing all rows.
  useEffect(() => {
    if (rowsWithErrors.size === 0 && currentFilter === "rowErrors") {
      showAllRows();
    }
  }, [rowsWithErrors.size, currentFilter, showAllRows]);

  // If ther user is only showing rows with errors in a single column, but then
  // fixes the last error, we want to automatically go back to showing all rows.
  useEffect(() => {
    if (
      currentFilter === "singleColumnErrors" &&
      currentColumnIndexErrorFilter !== null
    ) {
      const errors = colErrors.get(currentColumnIndexErrorFilter);
      if (!errors || errors.length === 0) {
        showAllRows();
      }
    }
  }, [colErrors, currentFilter, currentColumnIndexErrorFilter, showAllRows]);

  const onAlertModalPrimaryButtonClick = () => {
    switch (alertModal.action) {
      case "submit":
        setAlertModal({
          show: false,
          action: "submit",
        });
        onFinish();
        break;
      case "cancel":
        terminateWorker();
        dispatch(handleCancelModal(connection));
        break;
      case "back":
        terminateWorker();
        goBack();
    }
  };

  const onGoBackButtonClick = (): void => {
    if (isChangedByUserRef.current) {
      // show the "u sure?" modal
      setAlertModal({
        show: true,
        action: "back",
      });
    } else {
      terminateWorker();
      goBack();
    }
  };

  const getAlertModalData = () => {
    if (alertModal.action === "submit") {
      if (submitConfirmationOverride) {
        const { submitButtonText, cancelButtonText } =
          submitConfirmationOverride.options ?? {};
        return {
          alertModalMessage: sanitizeHTML(
            submitConfirmationOverride.messageHTML
          ),
          primaryButtonText:
            submitButtonText !== undefined ? submitButtonText : t("common.yes"),
          secondaryButtonText:
            cancelButtonText !== undefined ? cancelButtonText : t("common.no"),
          primaryButtonDescriptionText: "",
          secondaryButtonDescriptionText: "",
          onPrimaryButtonClick: onAlertModalPrimaryButtonClick,
        };
      }

      if (rowsWithErrors.size === 0) {
        return {
          alertModalMessage: t("dataReviewModal.submitAlert"),
          primaryButtonText: t("common.yes"),
          secondaryButtonText: t("common.no"),
          primaryButtonDescriptionText: "",
          secondaryButtonDescriptionText: "",
          onPrimaryButtonClick: onAlertModalPrimaryButtonClick,
        };
      }

      const allRowsHaveErrors = rowsWithErrors.size === fullData.length;

      const unresolvedMessage = t(
        allRowsHaveErrors
          ? "dataReviewModal.unresolvedErrorsAlert.titleAll"
          : "dataReviewModal.unresolvedErrorsAlert.title",
        {
          count: rowsWithErrors.size,
          defaultValue: t("dataReviewModal.unresolvedErrorsAlert.title_other", {
            count: rowsWithErrors.size,
          }),
        }
      );

      if (invalidDataBehavior === EInvalidDataBehavior.BLOCK_SUBMIT) {
        // We get here because of a race condition - the user should have never been able to click
        // "Finish" in the first place, because the button is disabled if there are any invalid rows.
        // However, if there are no invalid rows when the user clicks "Finish", but then an async
        // callback adds an error after this modal is already open, we're in a pickle.
        return {
          alertModalMessage: unresolvedMessage,
          primaryButtonText: t("common.goBack"),
          primaryButtonDescriptionText: t(
            "v1.dataReviewModal.unresolvedErrorsAlert.reviewAndFix"
          ),
          secondaryButtonText: "",
          secondaryButtonDescriptionText: "",
          onPrimaryButtonClick: () =>
            setAlertModal({
              show: false,
              action: "cancel",
            }),
        };
      }

      return {
        alertModalMessage: unresolvedMessage,
        primaryButtonText: t(
          "dataReviewModal.unresolvedErrorsAlert.submitButton"
        ),
        secondaryButtonText: t("common.goBack"),
        primaryButtonDescriptionText:
          invalidDataBehavior === EInvalidDataBehavior.INCLUDE_INVALID_ROWS
            ? t("v1.dataReviewModal.unresolvedErrorsAlert.submitAnyway")
            : t(
                rowsWithErrors.size === fullData.length
                  ? "v1.dataReviewModal.unresolvedErrorsAlert.discardAllAndSubmit"
                  : "v1.dataReviewModal.unresolvedErrorsAlert.discardAndSubmit",
                {
                  count: rowsWithErrors.size,
                  defaultValue: t(
                    "v1.dataReviewModal.unresolvedErrorsAlert.discardAndSubmit",
                    {
                      count: rowsWithErrors.size,
                    }
                  ),
                }
              ),
        secondaryButtonDescriptionText: t(
          "v1.dataReviewModal.unresolvedErrorsAlert.reviewAndFix"
        ),
        hasAlertModalError: true,
        alertModalCaption:
          invalidDataBehavior === EInvalidDataBehavior.INCLUDE_INVALID_ROWS
            ? t(
                "dataReviewModal.unresolvedErrorsAlert.includeInvalidRows.subtitle"
              )
            : allRowsHaveErrors
            ? t(
                "dataReviewModal.unresolvedErrorsAlert.removeInvalidRows.subtitleAll"
              )
            : t(
                "dataReviewModal.unresolvedErrorsAlert.removeInvalidRows.subtitle",
                {
                  count: rowsWithErrors.size,
                }
              ),
        children: (
          <Button
            theme="ghost"
            className="px-0 mt-6 font-medium gap-2"
            onClick={downloadRichXLSX}
            data-cy="download-xlsx-with-errors"
          >
            <ExportIcon />
            <Text type="body" as="span">
              {t(
                "dataReviewModal.unresolvedErrorsAlert.downloadXLSXWithErrorsNew"
              )}
            </Text>
          </Button>
        ),
        onPrimaryButtonClick: onAlertModalPrimaryButtonClick,
      };
    }

    return {
      alertModalMessage: t("common.clearAllProgressAlert"),
      primaryButtonText: t("common.yes"),
      secondaryButtonText: t("common.no"),
      primaryButtonDescriptionText: "",
      secondaryButtonDescriptionText: "",
      onPrimaryButtonClick: onAlertModalPrimaryButtonClick,
    };
  };

  const FinishButton = () => {
    const errorRowCount = rowsWithErrors.size;

    const DisabledButtonWithToolTip: React.FC = ({ children }) => {
      return (
        <div className="relative group">
          <Button
            data-cy="finish-button"
            className="m-0 pointer-events-none "
            onClick={() => {}}
            disabled
          >
            {t("common.finish")}
          </Button>
          <span className="hidden group-hover:inline-block absolute bottom-full left-1/2 -translate-y-1 -translate-x-1/2 text-sm bg-black/80 rounded-md w-48 text-center p-2 text-white">
            {children}
          </span>
        </div>
      );
    };

    const NormalFinishButton = () => {
      const handleClick = async () => {
        await dispatch(
          handleStepHook(connection, EStepHook.REVIEW_STEP_PRE_SUBMIT)
        );

        if (importerMode === "DEMO") {
          setShowUpsellModal(true);
        } else {
          setAlertModal({
            show: true,
            action: "submit",
          });
        }
      };

      return (
        <Button
          data-cy="finish-button"
          className="m-0"
          onClick={handleClick}
          autoFocus
        >
          {t("common.finish")}
        </Button>
      );
    };

    if (isLoading) {
      return null;
    } else if (
      !settings.allowEmptySubmit &&
      fullData.length === emptyRowCount
    ) {
      return (
        <DisabledButtonWithToolTip>
          {t("dataReviewModal.cannotSubmitEmpty")}
        </DisabledButtonWithToolTip>
      );
    } else if (
      invalidDataBehavior === EInvalidDataBehavior.BLOCK_SUBMIT &&
      errorRowCount > 0
    ) {
      return (
        <DisabledButtonWithToolTip>
          {t("dataReviewModal.unresolvedErrorsAlert.title", {
            count: errorRowCount,
          })}
        </DisabledButtonWithToolTip>
      );
    } else if (
      !settings.allowEmptySubmit &&
      invalidDataBehavior !== EInvalidDataBehavior.INCLUDE_INVALID_ROWS &&
      errorRowCount + emptyRowCount >= fullData.length
    ) {
      return (
        <DisabledButtonWithToolTip>
          {t("dataReviewModal.unresolvedErrorsAlert.titleAll")}
        </DisabledButtonWithToolTip>
      );
    } else {
      return <NormalFinishButton />;
    }
  };

  const {
    alertModalMessage,
    primaryButtonText,
    secondaryButtonText,
    primaryButtonDescriptionText,
    secondaryButtonDescriptionText,
    children,
    onPrimaryButtonClick,
    hasAlertModalError,
    alertModalCaption,
  } = getAlertModalData();

  const handleShowAlert = (show: boolean) =>
    setAlertModal({
      show,
      action: "cancel",
    });

  const handleAlertSecondaryButtonClick = () =>
    setAlertModal({
      show: false,
      action: "cancel",
    });

  const handleCloseUpsellModal = () => {
    setShowUpsellModal(false);
    setAlertModal({
      show: true,
      action: "submit",
    });
  };

  const processCellChanges = async (
    fullData: FullDataWithMeta,
    changes: HotChange[],
    useWorker = true
  ): Promise<void> => {
    const shouldUseWorker =
      settings.backendCapabilities.allow_worker_processing &&
      useWorker &&
      fullData.length > 10000;

    if (shouldUseWorker) {
      setIsProcessingChanges(true);
    }

    try {
      const processedData = shouldUseWorker
        ? await processChangesInWorker(fullData, changes, dispatch)
        : dispatch(processChanges(fullData, changes));

      setFullData(processedData);

      const postHookData = await dispatch(
        runRowHooks(
          processedData,
          (...args) => dispatch(handleRowHooks(connection, ...args)),
          "update",
          changes
        )
      );

      setFullData(postHookData);
    } catch (error) {
      console.error("Error processing changes:", error);
      if (shouldUseWorker) {
        return processCellChanges(fullData, changes, false);
      }
    } finally {
      if (shouldUseWorker) {
        setIsProcessingChanges(false);
      }
    }
  };

  const handleCellDataChange = async (
    _: any[][],
    changes: HotChange[]
  ): Promise<void> => {
    /**
     * Whenever a user clicks on a cell and doesn't change anything, handsontable
     * will still trigger a change, but the prevValue and newValue will be the same.
     * This is a hack to prevent those changes from being processed.
     * Example: [["", "", "prev", "prev"], ["", "", "new", "new"]]
     */
    if (
      changes.length === 0 ||
      (changes.length === 1 && changes[0][2] === changes[0][3])
    ) {
      return;
    }

    isChangedByUserRef.current = true;

    // Apply the changes to the full data without running validations.
    // This is to make sure user has immediate feedback on their changes
    // instead of waiting for the worker to process everything.
    if (
      fullData.length > 10000 &&
      settings.backendCapabilities.allow_worker_processing
    ) {
      flushSync(() => {
        const postChangeData = updateFullData(
          fullData,
          changes,
          mappedFieldInstances,
          () => buildEmptyRowFromMappedFieldInstances(mappedFieldInstances)
        );
        setFullData(postChangeData);
      });
    }

    try {
      await processCellChanges(fullData, changes);
    } finally {
      setIsProcessingChanges(false);
    }
  };

  const handleRemoveRows = (removedRows: number[]): void => {
    const newFullData = dispatch(
      removeRows(fullData, removedRows, (...args) =>
        dispatch(handleRowDeleteHooks(connection, ...args))
      )
    );

    setFullData(newFullData);
    isChangedByUserRef.current = true;
  };

  const handleCreateRow = (addedRows: number[]): void => {
    const newFullData = dispatch(addEmptyRows(fullData, addedRows));
    setFullData(newFullData);
    isChangedByUserRef.current = true;
  };

  const handleFindAndReplace = async (
    query: string,
    replacement: string,
    opts: SearchOpts
  ) => {
    const newFullData = await dispatch(
      findAndReplace(
        fullData,
        (...args) => dispatch(handleRowHooks(connection, ...args)),
        query,
        replacement,
        opts
      )
    );
    setFullData(newFullData);
    isChangedByUserRef.current = true;
  };

  const handleUserFunctionChange = async (
    transformResult: TransformDataSuccess
  ) => {
    const newFullData = await dispatch(
      runTransformDataChanges(
        fullData,
        transformResult,
        (...args) => dispatch(handleRowHooks(connection, ...args)),
        (...args) => dispatch(handleRowDeleteHooks(connection, ...args))
      )
    );
    setFullData(newFullData);
    isChangedByUserRef.current = true;
  };

  const handleOpenUserFunction = () => {
    setIsUserFunctionOpen(true);
  };

  const handleCloseUserFunction = () => {
    setIsUserFunctionOpen(false);
  };

  const showTransformDataButton =
    settings.reviewStep.enableUserTransformations &&
    settings.backendCapabilities.transform_data;

  const showExportButton =
    settings.backendCapabilities.allow_excel_export !== false;

  const handleToggleSearch = () => {
    setIsSearchOpen((prev) => !prev);
  };

  const handleClearSearchResults = () => {
    setSearchResults(null);
    setHiddenSearchRows(new Set());
    lastQuery.current = null;
  };

  const handleSearch = useCallback(
    (value: string) => {
      flushSync(() => {
        handleClearSearchResults();

        if (value.length === 0) {
          return;
        }

        const { matches, unmatchedRows } = dispatch(search(fullData, value));

        setSearchResults(matches);
        setHiddenSearchRows(unmatchedRows);
        lastQuery.current = value;
      });
    },
    [fullData, dispatch]
  );

  useEffect(() => {
    if (lastQuery.current !== null) {
      handleSearch(lastQuery.current);
    }
  }, [fullData, handleSearch]);

  useEffect(() => {
    // Cleanup when component unmounts
    return () => {
      terminateWorker();
    };
  }, []);

  return (
    <div>
      {showTransformDataButton && (
        <UserFunction
          isOpen={isUserFunctionOpen}
          onClose={handleCloseUserFunction}
          fullData={fullData}
          onConfirmChanges={handleUserFunctionChange}
        />
      )}
      <AlertModal
        show={alertModal.show}
        setShow={handleShowAlert}
        message={alertModalMessage}
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
        primaryButtonDescriptionText={primaryButtonDescriptionText}
        secondaryButtonDescriptionText={secondaryButtonDescriptionText}
        onPrimaryButtonClick={onPrimaryButtonClick}
        onSecondaryButtonClick={handleAlertSecondaryButtonClick}
        showSecondaryButton={secondaryButtonText !== ""}
        errorModal={hasAlertModalError}
        caption={alertModalCaption}
        data-cy="data-review-alert"
      >
        {children}
      </AlertModal>
      <UpsellModal
        show={showUpsellModal}
        onClose={handleCloseUpsellModal}
      ></UpsellModal>
      <FindAndReplaceModal
        show={showFindAndReplace}
        onClose={() => setShowFindAndReplace(false)}
        handleFindAndReplace={handleFindAndReplace}
      />
      <StepContainer
        data-cy="DataReviewModal"
        onBack={canGoBack && onGoBackButtonClick}
        step="review"
        contentClassName="!p-2 md:!p-4"
      >
        <Card className="!p-0 md:!p-0 overflow-hidden relative">
          {isLoading ? (
            <div className="flex flex-col justify-center h-80">
              <div className="flex justify-center">
                <Spinner role="status" />
              </div>
              {stepSettings.processingText && (
                <Text type="body" className="mt-2 text-center">
                  {stepSettings.processingText}
                </Text>
              )}
            </div>
          ) : (
            <>
              <header className="!pt-6 !pl-6 !pr-6 !pb-4">
                <div className="flex items-start">
                  <div className="flex items-center gap-2">
                    <Text type="h1">{t("modalHeader.review")}</Text>
                    {isProcessingChanges && (
                      <div className="flex items-center justify-center">
                        <Spinner
                          role="status"
                          className="w-4 h-4 !text-gray-500"
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex ml-auto whitespace-nowrap gap-2">
                    {rowsWithErrors.size > 0 ? (
                      <ErrorFilterButton
                        currentFilter={currentFilter}
                        onFilterAllErrorsRows={() =>
                          showOnlyRowsWithErrors(fullData, rowsWithErrors)
                        }
                        onClearFilter={showAllRows}
                      />
                    ) : (
                      <div className="!p-2 rounded-lg text-green-700 bg-green-50 !text-sm font-bold flex items-center gap-2 whitespace-nowrap">
                        <CheckIcon className="w-3 h-4" />
                        <Text type="inherit">
                          {t(
                            "dataReviewModal.errorActionButton.noRowsWithError"
                          )}
                        </Text>
                      </div>
                    )}

                    <Button
                      onClick={() => setShowFindAndReplace(true)}
                      theme="secondary"
                      className="text-sm"
                    >
                      {t("dataReviewModal.findAndReplace.title")}
                    </Button>
                    {showTransformDataButton && (
                      <Button
                        theme="secondary"
                        className="text-sm"
                        onClick={handleOpenUserFunction}
                        data-cy="transform-data-button"
                      >
                        {t("userFunctionModal.title")}
                      </Button>
                    )}

                    {showExportButton && (
                      <ButtonDropdown
                        theme="secondary"
                        options={[
                          {
                            children: `${t(
                              "dataReviewModal.exportButton.all"
                            )}`,
                            onClick: downloadRichXLSX,
                          },
                          ...(rowsWithErrors.size > 0
                            ? [
                                {
                                  children: `${t(
                                    "dataReviewModal.exportButton.errorsOnly"
                                  )}`,
                                  onClick: downloadRichXLSXOnlyErrors,
                                },
                              ]
                            : []),
                        ]}
                      >
                        <ExportIcon />
                        <span>{t("dataReviewModal.exportButton.title")}</span>
                      </ButtonDropdown>
                    )}
                    <Button
                      theme="secondary"
                      onClick={handleToggleSearch}
                      className="p-2"
                    >
                      <Search className="w-5 h-5" />
                    </Button>
                  </div>
                </div>
                {isSearchOpen && (
                  <div className="flex justify-end mt-2">
                    <SearchInput
                      onSubmit={handleSearch}
                      onClose={handleToggleSearch}
                      rowsMatched={fullData.length - hiddenSearchRows.size}
                      cellsMatched={
                        searchResults ? searchResults.size : undefined
                      }
                    />
                  </div>
                )}
                <div className="space-y-2 w-full mt-3">
                  {renderAlertMessages()}
                </div>
              </header>
              <div>
                <TableWrapper
                  tableData={fullData.map((r) => [...r])}
                  onCellDataChange={handleCellDataChange}
                  onRemoveRow={handleRemoveRows}
                  onCreateRow={handleCreateRow}
                  hiddenRows={
                    new Set<number>([...hiddenRows, ...hiddenSearchRows])
                  }
                  hiddenRowsVersion={hiddenRowsVersion}
                  autoAddRow={
                    currentFilter === "none" && stepSettings.allowAddingRows
                  }
                  selectedCell={selectedHandsonCell}
                  tableHeight="57vh"
                  onFilterColumn={(colIndex: number | null) => {
                    if (colIndex === null) {
                      showAllRows();
                    } else {
                      showOnlySingleColumnErrors(fullData, colErrors, colIndex);
                    }
                  }}
                  currentColumnIndexErrorFilter={currentColumnIndexErrorFilter}
                  searchResults={searchResults}
                />
              </div>
            </>
          )}
        </Card>

        <footer className="flex gap-2 items-center justify-end w-full mt-6">
          <FinishButton />
        </footer>
      </StepContainer>
    </div>
  );
};

export default DataReviewModal;
