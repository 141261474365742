import {
  selectRawRowWidth,
  SelectOptionOverrides,
} from "../store/reducers/coredata";
import { runValidators } from "../helpers/Validators";
import { RootState } from "../store/reducers";
import {
  IRowHookInput,
  IRowHookOutputInternal,
  IRowMeta,
  ITableMessage,
  ITableMessageInternal,
  ITableMessages,
  INewTableMessages,
  IRowCell,
  IRowHookCell,
  IRowCellManyToOne,
  IDeveloperField,
  IDeveloperSelectOption,
} from "../interfaces";
import { fieldFromDeveloperField, IAbstractField, IField } from "../fields";
import { SelectField } from "../fields/select";
import { v4 as uuidv4 } from "uuid";
import {
  selectMappedFieldInstances,
  selectMappedSpecs,
  selectKeyToIndexMap,
} from "../store/selectors";
import { ColumnMapping, FieldSpecMap } from "../store/reducers/fields";
import { addBreadcrumb } from "@sentry/browser";

export type ParsedData = unknown[][];
export type DataWithMetaRow = [...unknown[], IRowMeta];
export type FullDataWithMeta = DataWithMetaRow[];
export type HotChange = [
  rowIndex: number,
  colIndex: number,
  oldValue: unknown,
  newValue: string | null
];
export type SelectOptionOverride = [
  rowIndex: number,
  colIndex: number,
  selectOptions: IDeveloperSelectOption[]
];

export enum CoreDataRowAction {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

export const filterTableMessages = (
  tableMessages: ITableMessages,
  filterFn: (
    msg: ITableMessageInternal,
    rowIndex: number,
    colIndex: number
  ) => boolean
): ITableMessages => {
  const newMessages: ITableMessages = new Map();

  for (const [rowIndex, rowMessages] of tableMessages) {
    for (const [colIndex, messages] of rowMessages) {
      const filteredMessages = messages.filter((msg) =>
        filterFn(msg, rowIndex, colIndex)
      );
      if (filteredMessages.length > 0) {
        addTableMessages(newMessages, rowIndex, colIndex, filteredMessages);
      }
    }
  }

  return newMessages;
};

export const selectNewTableMessages = (state: RootState): ITableMessages => {
  return new Map(
    Array.from(state.coredata.tableMessages).map(([rowIndex, rowMessages]) => [
      rowIndex,
      new Map(rowMessages),
    ])
  );
};

export const selectNewTransformErrorCells = (state: RootState): Set<string> => {
  return new Set(state.coredata.transformErrorCells.keys());
};

export const buildEmptyRow = (state: RootState): DataWithMetaRow => {
  const fieldInstances = selectMappedFieldInstances(state);
  const rowMeta: IRowMeta = { rowId: uuidv4(), originalIndex: null };
  const newRow: DataWithMetaRow = [
    ...Array.from({ length: selectRawRowWidth(fieldInstances) }).map(
      (_, colIdx) => {
        const field = fieldInstances.get(colIdx);
        if (field) {
          return field.getInitialValue();
        } else {
          return "";
        }
      }
    ),
    rowMeta,
  ];
  return newRow;
};

export const buildRowHookInputRow = (
  fullData: FullDataWithMeta,
  rowIndex: number,
  fieldSpecs: Map<number, IDeveloperField>,
  fieldInstances: Map<number, IAbstractField>,
  transformErrorCells: Set<string>,
  userMessages: ITableMessages
): IRowHookInput => {
  const rowHookInputDataRow: IRowHookInput = {
    row: {},
    index: rowIndex,
    rowId: (getRowMeta(fullData[rowIndex]) ?? {}).rowId,
  };

  fieldSpecs.forEach((fieldSpec, colIdx) => {
    const cellRef = `${rowIndex},${colIdx}`;
    const field = fieldInstances.get(colIdx)!;

    const cellValue = fullData[rowIndex][colIdx] ?? "";
    const hasTransformError = transformErrorCells.has(cellRef);
    let selectOptions;

    if (field.type === "select") {
      const selectField = field as SelectField;
      if (selectField.selectOptionOverrideMap.has(rowIndex)) {
        selectOptions = [
          ...selectField.selectOptionOverrideMap.get(rowIndex)!.entries(),
        ].map(([label, value]) => ({
          label,
          value,
        }));
      }
    }

    const fieldObj: IRowHookCell = {
      value: hasTransformError
        ? cellValue
        : field.getEditValueChecked(cellValue, rowIndex),
      resultValue: hasTransformError
        ? null
        : field.getOutputValueChecked(cellValue, rowIndex),
      info: (userMessages.get(rowIndex)?.get(colIdx) || []).map(
        (m: ITableMessageInternal): ITableMessage => ({
          level: m.level,
          message: m.message,
        })
      ),
    };

    if (selectOptions) fieldObj.selectOptions = selectOptions;

    if (fieldSpec.manyToOne) {
      rowHookInputDataRow.row[fieldSpec.key] ??= {
        manyToOne: [],
        value: undefined,
        resultValue: undefined,
      };
      rowHookInputDataRow.row[fieldSpec.key].manyToOne!.push(fieldObj);
    } else {
      rowHookInputDataRow.row[fieldSpec.key] = fieldObj;
    }
  });

  return rowHookInputDataRow;
};

export const getChangesAndMessagesFromHookOutput = (
  state: RootState,
  fullData: FullDataWithMeta,
  rowHookOutputRows: IRowHookOutputInternal[]
): [HotChange[], INewTableMessages, SelectOptionOverride[]] => {
  const changes: [number, number, any, any][] = [];
  const messages: INewTableMessages = [];
  const selOptionsNew: SelectOptionOverride[] = [];
  const keyToIndexMap = selectKeyToIndexMap(state);

  rowHookOutputRows.forEach((hookOutputRow) => {
    const rowChanges: {
      cell: IRowCell;
      key: string;
      colIndex: number;
    }[] = [];

    for (const key in hookOutputRow.row) {
      // ensure key is in a mapped column
      const colIndexEntry = keyToIndexMap.get(key);

      if (colIndexEntry === undefined) continue;

      const cell = hookOutputRow.row[key];

      if (colIndexEntry.manyToOne) {
        colIndexEntry.indexes.forEach((colIndex, manyToOneIndex) =>
          rowChanges.push({
            cell: (cell as IRowCellManyToOne).manyToOne[manyToOneIndex],
            key,
            colIndex,
          })
        );
      } else {
        rowChanges.push({
          cell: cell as IRowCell,
          key,
          colIndex: colIndexEntry.index,
        });
      }
    }

    rowChanges.forEach(({ cell, colIndex }) => {
      const { info, value, selectOptions } = cell;

      if (value !== undefined) {
        const oldValue = fullData[hookOutputRow.index][colIndex];
        changes.push([hookOutputRow.index, colIndex, oldValue, value]);
      }

      if (info) {
        const tableMessages = info.map((userMessage) => ({
          ...userMessage,
          type: "user-generated" as const,
        }));

        messages.push([hookOutputRow.index, colIndex, tableMessages]);
      }

      if (selectOptions) {
        selOptionsNew.push([hookOutputRow.index, colIndex, selectOptions]);
      }
    });
  });

  return [changes, messages, selOptionsNew];
};

/**
 * This function add rows to the data or removes rows from the data. When adding rows, the affectedRowIndices
 * are expected to be consecutive. When removing rows, the affectedRowIndices can be of any rows in the data.
 */
export const addOrRemoveRowsFromData = (
  state: RootState,
  fullData: FullDataWithMeta,
  affectedRowIndices: number[],
  mode: CoreDataRowAction
): FullDataWithMeta => {
  const newFullData = cloneFullData(fullData);
  const rowSet = new Set(affectedRowIndices.sort());

  if (mode === CoreDataRowAction.ADD) {
    const newRows = affectedRowIndices.map(() => buildEmptyRow(state));
    newFullData.splice(affectedRowIndices[0], 0, ...newRows);
  } else {
    for (let i = newFullData.length - 1; i >= 0; i--) {
      if (rowSet.has(i)) {
        newFullData.splice(i, 1);
      }
    }
  }

  return newFullData;
};

export const offsetTableMessagesAfterRowChange = (
  tableMessages: ITableMessages,
  affectedRowIndexes: number[],
  mode: CoreDataRowAction
): ITableMessages => {
  if (affectedRowIndexes.length === 0) return tableMessages;
  const newTableMessages: ITableMessages = new Map();

  for (const [rowIndex, rowMessages] of tableMessages) {
    const newRowIndex = getOffsetRow(rowIndex, affectedRowIndexes, mode);
    if (newRowIndex !== null) {
      newTableMessages.set(newRowIndex, rowMessages);
    }
  }

  return newTableMessages;
};

/**
 *  This function returns a new map with the tableMessages that belong to
 *  the rowsWithErrors set, the return tableMessages row indexes starts from 0.
 */
export const getOffsetTableMessages = (
  oldTableMessages: ITableMessages,
  rowsWithErrors: Set<number>
): ITableMessages => {
  const newErrorRowIndexes = [...rowsWithErrors]
    .sort((a, b) => a - b)
    .reduce(
      (map, oldIndex, newIndex) => map.set(oldIndex, newIndex),
      new Map<number, number>()
    );

  const errorTableMessages: ITableMessages = new Map();

  for (const [rowIndex, rowMessages] of oldTableMessages.entries()) {
    for (const [colIndex, messages] of rowMessages) {
      if (rowsWithErrors.has(rowIndex)) {
        const newRowIndex = newErrorRowIndexes.get(rowIndex)!;
        addTableMessages(errorTableMessages, newRowIndex, colIndex, messages);
      }
    }
  }

  return errorTableMessages;
};

export const offsetSetEntriesAfterRowChange = (
  keySet: Set<string>,
  affectedRowIndices: number[],
  mode: CoreDataRowAction
): Set<string> => {
  const updatedSet = new Set<string>();

  keySet.forEach((key) => {
    const newKey = getOffsetKey(key, affectedRowIndices, mode);
    if (newKey) updatedSet.add(newKey);
  });

  return updatedSet;
};

export const offsetSelectOptionOverrideMaps = (
  selectOptionOverrides: SelectOptionOverrides,
  affectedRowIndexes: number[],
  mode: CoreDataRowAction
): SelectOptionOverrides => {
  const newSelectOverrides: SelectOptionOverrides = new Map();

  selectOptionOverrides.forEach((overrideMap, fieldKey) => {
    const overrideEntries = [...overrideMap];
    const newFieldOverrides: Map<number, Map<string, string>> = new Map();

    overrideEntries.forEach(([rowIdx, values]) => {
      const newKey = getOffsetKey(rowIdx.toString(), affectedRowIndexes, mode);
      if (newKey) newFieldOverrides.set(parseInt(newKey), values);
    });

    newSelectOverrides.set(fieldKey, newFieldOverrides);
  });

  return newSelectOverrides;
};

/**
 * This function returns the new row index for a given row index after a cell action
 * If the row has been removed, reutrns null.
 */
const getOffsetRow = (
  rowIndex: number,
  affectedRowIndexes: number[],
  mode: CoreDataRowAction
): number | null => {
  const rowOffset = affectedRowIndexes.reduce(
    (acc, cur) => acc + (cur <= rowIndex ? 1 : 0),
    0
  );

  if (mode === CoreDataRowAction.REMOVE) {
    if (!affectedRowIndexes.includes(rowIndex)) {
      return rowIndex - rowOffset;
    } else {
      return null;
    }
  } else {
    return rowIndex + rowOffset;
  }
};
/**
 * This function returns the new key for a given cell key after an offset action.
 * If the key is in a row that has been removed, returns null.
 */
const getOffsetKey = (
  key: string,
  affectedRowIndices: number[],
  mode: CoreDataRowAction
): string | null => {
  const [row, col] = key.split(",").map((n: string) => parseInt(n));
  const newRow = getOffsetRow(row, affectedRowIndices, mode);
  if (newRow === null) return null;
  return `${newRow},${col}`;
};

export const getRowMeta = (row: DataWithMetaRow): IRowMeta =>
  row.at(-1) as IRowMeta;

export const addRowMetaToData = (data: ParsedData): FullDataWithMeta => {
  if (data.length === 0 || data[0].length === 0) {
    return data as FullDataWithMeta;
  }

  return data.map((row, originalIndex): DataWithMetaRow => {
    return [
      ...row,
      {
        rowId: uuidv4(),
        originalIndex,
      },
    ];
  });
};

export const addTableMessage = (
  tableMessages: ITableMessages,
  rowIndex: number,
  colIndex: number,
  newMessage: ITableMessageInternal
): void => {
  addTableMessages(tableMessages, rowIndex, colIndex, [newMessage]);
};

export const addTableMessages = (
  tableMessages: ITableMessages,
  rowIndex: number,
  colIndex: number,
  newMessages: ITableMessageInternal[]
): ITableMessages => {
  if (newMessages.length === 0) return tableMessages;

  let rowMessages: Map<number, ITableMessageInternal[]>;

  if (tableMessages.has(rowIndex)) {
    rowMessages = tableMessages.get(rowIndex)!;
  } else {
    rowMessages = new Map();
    tableMessages.set(rowIndex, rowMessages);
  }

  if (rowMessages.has(colIndex)) {
    rowMessages.set(colIndex, rowMessages.get(colIndex)!.concat(newMessages));
  } else {
    rowMessages.set(colIndex, newMessages);
  }

  return tableMessages;
};

export const buildRowHookInputRows = (
  state: RootState,
  fullData: FullDataWithMeta,
  changedRowIndexes: number[] | Set<number> | null
): IRowHookInput[] => {
  const userMessages = filterTableMessages(
    state.coredata.tableMessages,
    (msg) => msg.type === "user-generated"
  );
  const transformErrorCells = state.coredata.transformErrorCells;
  const fieldSpecs = selectMappedSpecs(state);
  const fieldInstances = selectMappedFieldInstances(state);

  if (changedRowIndexes) {
    return [...changedRowIndexes]
      .filter((rowIndex) => rowIndex in fullData)
      .map((rowIndex) =>
        buildRowHookInputRow(
          fullData,
          rowIndex,
          fieldSpecs,
          fieldInstances,
          transformErrorCells,
          userMessages
        )
      );
  } else {
    return fullData.map((_row, rowIndex) =>
      buildRowHookInputRow(
        fullData,
        rowIndex,
        fieldSpecs,
        fieldInstances,
        transformErrorCells,
        userMessages
      )
    );
  }
};

export const filterTableMessagesForCell = (
  tableMessages: ITableMessages,
  rowIndex: number,
  colIndex: number,
  filterFn: (msg: ITableMessageInternal) => unknown
): void => {
  if (
    tableMessages.has(rowIndex) &&
    tableMessages.get(rowIndex)!.has(colIndex)
  ) {
    const messages = tableMessages.get(rowIndex)!.get(colIndex)!;
    const filteredMessages = messages.filter(filterFn);

    if (filteredMessages.length > 0) {
      tableMessages.get(rowIndex)!.set(colIndex, filteredMessages);
    } else {
      const rowMessages = tableMessages.get(rowIndex)!;
      rowMessages.delete(colIndex);
      if (rowMessages.size === 0) {
        tableMessages.delete(rowIndex);
      }
    }
  }
};

export const cloneFullData = (fullData: FullDataWithMeta): FullDataWithMeta =>
  fullData.map((row) => [...row]);

export const processFullDataChanges = (
  fullData: FullDataWithMeta,
  changes: HotChange[],
  fieldSpecs: FieldSpecMap,
  columnMapping: ColumnMapping,
  selectOptionOverrides: SelectOptionOverrides,
  tableMessages: ITableMessages,
  transformErrorCells: Set<string>,
  highlightAutoFixes: boolean
): {
  newFullData: FullDataWithMeta;
  newTableMessages: ITableMessages;
  newTransformErrorCells: Set<string>;
} => {
  const newFullData = cloneFullData(fullData);

  // Rebuild field instances from developer fields
  const fieldInstances = new Map<number, IField>();

  columnMapping.forEach(({ key }, colIndex) => {
    const field = fieldFromDeveloperField(
      fieldSpecs.get(key)!,
      selectOptionOverrides.get(colIndex)
    );
    fieldInstances.set(colIndex, field);
  });

  changes.forEach(([row, col, _oldVal, newVal]) => {
    const key = `${row},${col}`;

    transformErrorCells.delete(key);

    if (newFullData[row] === undefined)
      newFullData[row] = buildEmptyRowFromMappedFieldInstances(fieldInstances);

    const field = fieldInstances.get(col);
    if (!field) return;

    if (typeof newVal === "string") {
      newVal = newVal.trim();
    }

    const transformResult = field.transformChecked(newVal, row);

    filterTableMessagesForCell(
      tableMessages,
      row,
      col,
      (msg) =>
        msg.type !== "field-transform" && msg.type !== "transform-highlight"
    );

    if (transformResult.empty) {
      newFullData[row][col] = "";
      return;
    }

    if (transformResult.success) {
      const displayValue = (field as IAbstractField).getDisplayValue(
        transformResult.value,
        row
      );

      if (
        transformResult.highlight &&
        newVal !== displayValue &&
        highlightAutoFixes
      ) {
        addTableMessage(tableMessages, row, col, {
          type: "transform-highlight",
          level: "info",
          message: `Changed from "${newVal}"`,
        });
      }

      newFullData[row][col] = transformResult.value;
    } else {
      newFullData[row][col] = newVal;
      addTableMessage(tableMessages, row, col, transformResult.message);
      transformErrorCells.add(key);
    }
  });

  const specMap = new Map<number, IDeveloperField>();

  for (const [colIndex, { key }] of columnMapping.entries()) {
    specMap.set(colIndex, fieldSpecs.get(key)!);
  }

  // We use the rowsChanged to do two things: 1/ remove all the validation
  // messages for those rows and also limit the row validations being run to
  // those rows
  const rowsChanged = new Set(changes.map((change) => change[0]));

  const newMessages = filterTableMessages(
    tableMessages,
    (msg, rowIndex) =>
      // keep all the messages that are not column-validation or row-validation
      (msg.type !== "column-validation" && msg.type !== "row-validation") ||
      // keep all the row messages for rows that have not changed
      (!rowsChanged.has(rowIndex) && msg.type === "row-validation")
  );

  const validationMessages = runValidators(
    newFullData,
    specMap,
    fieldInstances,
    transformErrorCells,
    rowsChanged
  );

  for (const [rowIndex, colIndex, messages] of validationMessages) {
    messages.forEach((message) => {
      addTableMessage(newMessages, rowIndex, colIndex, message);
    });
  }

  return {
    newFullData,
    newTableMessages: newMessages,
    newTransformErrorCells: transformErrorCells,
  };
};

export const buildRowIdToIndexMap = (
  fullData: FullDataWithMeta
): Map<string, number> => {
  return fullData.reduce((map, row, idx) => {
    map.set(getRowMeta(row).rowId, idx);
    return map;
  }, new Map());
};

/**
 * Updates the full data with the given changes.
 * Returns the new, modified data.
 * DISCLAIMER: This shouldo only be used for optimistic updates, since it does not run validations.
 */
export const updateFullData = (
  fullData: FullDataWithMeta,
  changes: HotChange[],
  mappedFieldInstances: Map<number, IAbstractField>,
  buildEmptyRowFn: () => DataWithMetaRow
): FullDataWithMeta => {
  const newFullData = cloneFullData(fullData);

  changes.forEach(([row, col, _oldVal, newVal]) => {
    if (newFullData[row] === undefined) {
      newFullData[row] = buildEmptyRowFn();
    }

    const field = mappedFieldInstances.get(col);

    // If the field is not found, we return the new value purely
    // as if the field had a wrong transformation since we will
    // have no instance to transform the value.
    if (!field) {
      addBreadcrumb({
        message: `Field not found for column ${col}`,
        level: "error",
        data: {
          row,
          col,
          newVal,
        },
      });

      newFullData[row][col] = newVal;
      return;
    }

    if (typeof newVal === "string") {
      newVal = newVal.trim();
    }

    const transformResult = field.transformChecked(newVal, row);

    if (transformResult.empty) {
      newFullData[row][col] = "";
      return;
    }

    if (transformResult.success) {
      newFullData[row][col] = transformResult.value;
    } else {
      newFullData[row][col] = newVal;
    }
  });

  return newFullData;
};

export const buildEmptyRowFromMappedFieldInstances = (
  mappedFieldInstances: Map<number, IAbstractField>
): DataWithMetaRow => {
  const rowMeta: IRowMeta = { rowId: uuidv4(), originalIndex: null };

  // This is where we build the row (string[]) based on each field instance initial value.
  const newRow: DataWithMetaRow = [
    ...Array.from({ length: selectRawRowWidth(mappedFieldInstances) }).map(
      (_, colIdx) => {
        const field = mappedFieldInstances.get(colIdx);

        // If the field is not found, that means column is not mapped,
        // but we still need to add an empty cell, since fullData represents
        // the whole data, not only mapped columns data.
        if (!field) {
          return "";
        }

        return field.getInitialValue();
      }
    ),
    rowMeta,
  ];
  return newRow;
};
