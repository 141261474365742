import { IDeveloperField } from "./interfaces";

export const WORKIVA_FIELDS: IDeveloperField[] = [
  {
    label: "Risk ID",
    key: "riskId",
    validators: [
      { validate: "required" as const },
      { validate: "unique" as const },
    ],
    type: "string",
  },
  {
    label: "Risk Name",
    key: "name",
    type: "string",
  },
  {
    label: "Risk Description",
    key: "description",
    type: "string",
  },
  {
    label: "Process",
    key: "process",
    type: "string",
  },
  {
    label: "SubProcess",
    key: "subProcess",
    type: "string",
  },
  {
    label: "Risk Owner First Name",
    key: "riskOwnerFirstName",
    type: "string",
  },
  {
    label: "Risk Owner Last Name",
    key: "riskOwnerLastName",
    type: "string",
  },
  {
    label: "Risk Owner Username",
    key: "riskOwnerUsername",
    type: "email",
  },
  {
    label: "Mitigating Controls",
    key: "mitigatingControls",
    type: "string",
  },
];

export const DEMO_FIELDS: IDeveloperField[] = [
  {
    key: "companyName",
    type: "string",
    label: "Company Name",
    validators: [],
  },
  {
    key: "addressLine1",
    type: "string",
    label: "Address Line 1",
    validators: [],
  },
  {
    key: "addressLine2",
    type: "string",
    label: "Address Line 2",
    validators: [],
  },
  { key: "city", type: "string", label: "City", validators: [] },
  { key: "state", type: "string", label: "State", validators: [] },
  {
    key: "postalCode",
    type: ["number", "integer"],
    label: "Postal Code",
    validators: [],
  },
  { key: "website", type: "string", label: "Website", validators: [] },
  {
    key: "email",
    type: "email",
    label: "Email",
    validators: [{ validate: "unique" }],
  },
  { key: "firstName", type: "string", label: "First Name", validators: [] },
  { key: "lastName", type: "string", label: "Last Name", validators: [] },
  { key: "phone", type: "string", label: "Phone", validators: [] },
  { key: "logo", type: "string", label: "Logo", validators: [] },
  {
    key: "certifications",
    type: "string",
    label: "Certifications",
    validators: [],
  },
  {
    key: "capabilities",
    type: "string",
    label: "Capabilities",
    validators: [],
  },
  { key: "industries", type: "string", label: "Industries", validators: [] },
  {
    key: "classifications",
    type: "string",
    label: "Classifications",
    validators: [],
  },
  { key: "services", type: "string", label: "Services", validators: [] },
  {
    key: "description",
    type: "string",
    label: "Description",
    validators: [],
  },
];

export const SUPER_LARGE_FIELDS: IDeveloperField[] = [
  {
    key: "Company",
    label: "Company",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 35,
        errorMessage: "Max length is 35 characters",
      },
      {
        validate: "require_without",
        fields: ["LName", "FName"],
        errorMessage: "Required when First Name and Last Name are not provided",
      },
    ],
    requireMapping: true,
  },
  {
    key: "FName",
    label: "First Name",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 15,
        errorMessage: "Max length is 15 characters",
      },
      {
        validate: "require_without",
        fields: ["Company"],
        errorMessage: "Required when Company is not provided",
      },
    ],
    requireMapping: true,
  },
  {
    key: "LName",
    label: "Last Name",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 20,
        errorMessage: "Max length is 20 characters",
      },
      {
        validate: "require_without",
        fields: ["Company"],
        errorMessage: "Required when Company is not provided",
      },
    ],
    requireMapping: true,
  },
  {
    key: "Salutation",
    label: "Salutation",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 20,
        errorMessage: "Max length is 20 characters",
      },
    ],
  },
  {
    key: "SalutationName",
    label: "Salutation Name",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 35,
        errorMessage: "Max length is 35 characters",
      },
    ],
  },
  {
    key: "Address1",
    label: "Address 1",
    type: "string",
    requireMapping: true,
    validators: [
      {
        validate: "required",
        errorMessage: "A value is required",
      },
      {
        validate: "length",
        max: 30,
        errorMessage: "Max length is 30 characters",
      },
    ],
  },
  {
    key: "Address2",
    label: "Address 2",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 30,
        errorMessage: "Max length is 30 characters",
      },
    ],
  },
  {
    key: "City",
    label: "City",
    type: "string",
    requireMapping: true,
    validators: [
      {
        validate: "required",
        errorMessage: "A value is required",
      },
      {
        validate: "length",
        max: 20,
        errorMessage: "Max length is 20 characters",
      },
    ],
  },
  {
    key: "State",
    label: "State",
    type: "string",
    requireMapping: true,
    validators: [
      {
        validate: "required",
        errorMessage: "A value is required",
      },
    ],
  },
  {
    key: "Zip",
    label: "Zip Code",
    requireMapping: true,
    type: "string",
    validators: [
      {
        validate: "required",
        errorMessage: "A value is required",
      },
      {
        validate: "regex_match",
        regex: "^.{0,10}$",
        errorMessage: "Max length is 10 characters",
      },
      {
        validate: "regex_match",
        regex: "^(\\d{5}(-\\d{4})?)|(\\w\\d\\w\\s?\\d\\w\\d)$",
        errorMessage: "Invalid Zip Code",
      },
    ],
  },
  {
    key: "Country",
    label: "Country",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 20,
        errorMessage: "Max length is 20 characters",
      },
    ],
  },
  {
    key: "Title",
    label: "Title",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 12,
        errorMessage: "Max length is 12 characters",
      },
    ],
  },
  {
    key: "Phone",
    label: "Phone",
    type: "string",
    validators: [
      {
        validate: "regex_match",
        regex: "^.{0,12}$",
        errorMessage: "Max length is 12 characters",
      },
      {
        validate: "regex_match",
        regex: "^\\d{3}-\\d{3}-\\d{4}$",
        errorMessage: "Phone number format ###-###-#### is required",
      },
    ],
  },
  {
    key: "PhoneExt",
    label: "Phone Ext",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 5,
        errorMessage: "Max length is 5 characters",
      },
    ],
  },
  {
    key: "AltPhone",
    label: "Alt Phone",
    type: "string",
    validators: [
      {
        validate: "regex_match",
        regex: "^.{0,12}$",
        errorMessage: "Max length is 12 characters",
      },
      {
        validate: "regex_match",
        regex: "^\\d{3}-\\d{3}-\\d{4}$",
        errorMessage: "Phone number format ###-###-#### is required",
      },
    ],
  },
  {
    key: "AltPhoneExt",
    label: "Alt Phone Ext",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 5,
        errorMessage: "Max length is 5 characters",
      },
    ],
  },
  {
    key: "Mobile",
    label: "Mobile Phone",
    type: "string",
    validators: [
      {
        validate: "regex_match",
        regex: "^.{0,12}$",
        errorMessage: "Max length is 12 characters",
      },
      {
        validate: "regex_match",
        regex: "^\\d{3}-\\d{3}-\\d{4}$",
        errorMessage: "Phone number format ###-###-#### is required",
      },
    ],
  },
  {
    key: "MobileExt",
    label: "Mobile Phone Ext",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 5,
        errorMessage: "Max length is 5 characters",
      },
    ],
  },
  {
    key: "Fax",
    label: "Fax",
    type: "string",
    validators: [
      {
        validate: "regex_match",
        regex: "^.{0,12}$",
        errorMessage: "Max length is 12 characters",
      },
      {
        validate: "regex_match",
        regex: "^\\d{3}-\\d{3}-\\d{4}$",
        errorMessage: "Phone number format ###-###-#### is required",
      },
    ],
  },
  {
    key: "FaxExt",
    label: "Fax Ext",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 5,
        errorMessage: "Max length is 5 characters",
      },
    ],
  },
  {
    key: "EMail",
    label: "E-Mail",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 200,
        errorMessage: "Max length is 200 characters",
      },
    ],
  },
  {
    key: "Subdivision",
    label: "Subdivision",
    validators: [],
    type: "select",
    selectOptions: [
      {
        label: "",
        value: "",
      },
      {
        label: "Landfall",
        value: "Landfall",
      },
      {
        label: "NorthChase",
        value: "NorthChase",
      },
    ],
  },
  {
    key: "Comment",
    label: "Internal Comment",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 8000,
        errorMessage: "Max length is 8000 characters",
      },
    ],
  },
  {
    key: "Directions",
    label: "Instructions",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 8000,
        errorMessage: "Max length is 8000 characters",
      },
    ],
  },
  {
    key: "TaxExemptNum",
    label: "Tax Exempt #",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 20,
        errorMessage: "Max length is 20 characters",
      },
    ],
  },
  {
    key: "PrintBatchOutput",
    label: "Print Batch Output",
    type: "select",
    selectOptions: [
      {
        label: "",
        value: "",
      },
      {
        label: "Yes",
        value: "Yes",
      },
      {
        label: "No",
        value: "No",
      },
    ],
  },
  {
    key: "EmailBatchOutput",
    label: "Email Batch Output",
    type: "select",
    selectOptions: [
      {
        label: "",
        value: "",
      },
      {
        label: "Yes",
        value: "Yes",
      },
      {
        label: "No",
        value: "No",
      },
    ],
  },
  {
    key: "County",
    label: "County",
    type: "select",
    selectOptions: [
      {
        label: "",
        value: "",
      },
      {
        label: "ALBANY",
        value: "ALBANY",
      },
      {
        label: "ALBERTA",
        value: "ALBERTA",
      },
      {
        label: "ATLANTIC",
        value: "ATLANTIC",
      },
      {
        label: "BERGEN",
        value: "BERGEN",
      },
      {
        label: "BERKELEY",
        value: "BERKELEY",
      },
      {
        label: "BRUNSWICK",
        value: "BRUNSWICK",
      },
      {
        label: "BURLINGTON",
        value: "BURLINGTON",
      },
      {
        label: "CAMDEN",
        value: "CAMDEN",
      },
      {
        label: "CAPE MAY",
        value: "CAPE MAY",
      },
      {
        label: "CASS",
        value: "CASS",
      },
      {
        label: "CHARLESTON",
        value: "CHARLESTON",
      },
      {
        label: "CUMBERLAND",
        value: "CUMBERLAND",
      },
      {
        label: "ESSEX",
        value: "ESSEX",
      },
      {
        label: "FAIRFAX",
        value: "FAIRFAX",
      },
      {
        label: "FRANKLIN",
        value: "FRANKLIN",
      },
      {
        label: "FULTON",
        value: "FULTON",
      },
      {
        label: "GLOUCESTER",
        value: "GLOUCESTER",
      },
      {
        label: "HUDSON",
        value: "HUDSON",
      },
      {
        label: "LOUDOUN",
        value: "LOUDOUN",
      },
      {
        label: "MANASSAS PARK CITY",
        value: "MANASSAS PARK CITY",
      },
      {
        label: "MARICOPA",
        value: "MARICOPA",
      },
      {
        label: "MERCER",
        value: "MERCER",
      },
      {
        label: "MIDDLESEX",
        value: "MIDDLESEX",
      },
      {
        label: "MONMOUTH",
        value: "MONMOUTH",
      },
      {
        label: "MORRIS",
        value: "MORRIS",
      },
      {
        label: "NASSAU",
        value: "NASSAU",
      },
      {
        label: "NEW HANOVER",
        value: "NEW HANOVER",
      },
      {
        label: "NEW YORK",
        value: "NEW YORK",
      },
      {
        label: "OCEAN",
        value: "OCEAN",
      },
      {
        label: "ORANGE",
        value: "ORANGE",
      },
      {
        label: "PASSAIC",
        value: "PASSAIC",
      },
      {
        label: "PRINCE WILLIAM",
        value: "PRINCE WILLIAM",
      },
      {
        label: "QUEENS",
        value: "QUEENS",
      },
      {
        label: "RENSSELAER",
        value: "RENSSELAER",
      },
      {
        label: "RICHMOND",
        value: "RICHMOND",
      },
      {
        label: "SALEM",
        value: "SALEM",
      },
      {
        label: "SANTA CLARA",
        value: "SANTA CLARA",
      },
      {
        label: "SANTA CRUZ",
        value: "SANTA CRUZ",
      },
      {
        label: "SARATOGA",
        value: "SARATOGA",
      },
      {
        label: "SASKATCHEWAN",
        value: "SASKATCHEWAN",
      },
      {
        label: "SOMERSET",
        value: "SOMERSET",
      },
      {
        label: "UNION",
        value: "UNION",
      },
      {
        label: "WAKE",
        value: "WAKE",
      },
      {
        label: "WESTMORLAND",
        value: "WESTMORLAND",
      },
    ],
  },
  {
    key: "MapCode",
    label: "Map Code",
    type: "select",
    selectOptions: [
      {
        label: "",
        value: "",
      },
      {
        label: "M1",
        value: "M1",
      },
      {
        label: "M2",
        value: "M2",
      },
    ],
  },
  {
    key: "Division",
    label: "Division",
    type: "select",
    selectOptions: [
      {
        label: "",
        value: "",
      },
      {
        label: "CONSTRUCT",
        value: "CONSTRUCT",
      },
      {
        label: "LAWN",
        value: "LAWN",
      },
      {
        label: "MARKETING",
        value: "MARKETING",
      },
      {
        label: "PAINT",
        value: "PAINT",
      },
      {
        label: "SALES",
        value: "SALES",
      },
    ],
  },
  {
    key: "Type",
    label: "Type",
    type: "select",
    selectOptions: [
      {
        label: "",
        value: "",
      },
      {
        label: "FOOD",
        value: "FOOD",
      },
      {
        label: "HOSPITAL",
        value: "HOSPITAL",
      },
      {
        label: "NURSING HM",
        value: "NURSING HM",
      },
      {
        label: "SCH",
        value: "SCH",
      },
    ],
  },
  {
    key: "Builder",
    label: "Builder",
    type: "select",
    selectOptions: [
      {
        label: "",
        value: "",
      },
      {
        label: "T&C",
        value: "T&C",
      },
    ],
  },
  {
    key: "Source",
    label: "Source",
    type: "select",
    selectOptions: [
      {
        label: "",
        value: "",
      },
      {
        label: "93.5",
        value: "93.5",
      },
      {
        label: "CUSTOMER",
        value: "CUSTOMER",
      },
      {
        label: "FRIEND",
        value: "FRIEND",
      },
      {
        label: "I-787",
        value: "I-787",
      },
      {
        label: "MAIN ST",
        value: "MAIN ST",
      },
      {
        label: "TRIBUNE",
        value: "TRIBUNE",
      },
      {
        label: "WNYT",
        value: "WNYT",
      },
      {
        label: "WTEN",
        value: "WTEN",
      },
    ],
  },
  {
    key: "TaxCode",
    label: "Tax Code",
    type: "select",
    selectOptions: [
      {
        label: "",
        value: "",
      },
      {
        label: "10%",
        value: "10%",
      },
      {
        label: "8.125%",
        value: "8.125%",
      },
      {
        label: "8%",
        value: "8%",
      },
      {
        label: "DEF",
        value: "DEF",
      },
      {
        label: "GST",
        value: "GST",
      },
      {
        label: "GST HST",
        value: "GST HST",
      },
      {
        label: "GST PST",
        value: "GST PST",
      },
      {
        label: "PST",
        value: "PST",
      },
      {
        label: "US",
        value: "US",
      },
    ],
    requireMapping: true,
    validators: [
      {
        validate: "required",
        errorMessage: "A value is required",
      },
    ],
  },
  {
    key: "Branch",
    label: "Branch",
    type: "select",
    requireMapping: true,
    selectOptions: [
      {
        label: "",
        value: "",
      },
      {
        label: "APIAUTO",
        value: "APIAUTO",
      },
      {
        label: "APITestCalifornia",
        value: "APITestCalifornia",
      },
      {
        label: "APITestMain",
        value: "APITestMain",
      },
      {
        label: "APITestNorth",
        value: "APITestNorth",
      },
      {
        label: "APITestSouth",
        value: "APITestSouth",
      },
      {
        label: "Automation Branch 14291",
        value: "Automation Branch 14291",
      },
      {
        label: "Automation Branch 14395",
        value: "Automation Branch 14395",
      },
      {
        label: "Automation Branch 19254_1",
        value: "Automation Branch 19254_1",
      },
      {
        label: "Automation Branch 19254_2",
        value: "Automation Branch 19254_2",
      },
      {
        label: "Automation Branch 19526_1",
        value: "Automation Branch 19526_1",
      },
      {
        label: "Automation Branch 19526_2",
        value: "Automation Branch 19526_2",
      },
      {
        label: "Automation Branch 24047",
        value: "Automation Branch 24047",
      },
      {
        label: "Automation Branch 25426",
        value: "Automation Branch 25426",
      },
      {
        label: "Automation Branch 29918_1",
        value: "Automation Branch 29918_1",
      },
      {
        label: "Automation Branch 29918_2",
        value: "Automation Branch 29918_2",
      },
      {
        label: "Automation Branch I",
        value: "Automation Branch I",
      },
      {
        label: "Automation Branch II",
        value: "Automation Branch II",
      },
      {
        label: "Automation Branch III",
        value: "Automation Branch III",
      },
      {
        label: "Automation Branch IV",
        value: "Automation Branch IV",
      },
      {
        label: "Automation Branch V",
        value: "Automation Branch V",
      },
      {
        label: "California Branch",
        value: "California Branch",
      },
      {
        label: "CNA Branch",
        value: "CNA Branch",
      },
      {
        label: "GLbranch",
        value: "GLbranch",
      },
      {
        label: "Main Branch",
        value: "Main Branch",
      },
      {
        label: "ManagerBranch",
        value: "ManagerBranch",
      },
      {
        label: "North Branch",
        value: "North Branch",
      },
      {
        label: "Route Branch",
        value: "Route Branch",
      },
      {
        label: "South Branch",
        value: "South Branch",
      },
      {
        label: "Test Branch for 13718",
        value: "Test Branch for 13718",
      },
      {
        label: "Zeta Branch",
        value: "Zeta Branch",
      },
    ],
    validators: [
      {
        validate: "required",
        errorMessage: "A value is required",
      },
    ],
  },
  {
    key: "UserDef1",
    label: "Loc Txt",
    validators: [
      {
        validate: "length",
        max: 10,
        errorMessage: "Max length is 10 characters",
      },
    ],
    type: "string",
  },
  {
    key: "UserDef2",
    label: "Loc Ck",
    validators: [
      {
        validate: "length",
        max: 10,
        errorMessage: "Max length is 10 characters",
      },
    ],
    type: "select",
    selectOptions: [
      {
        label: "",
        value: "",
      },
      {
        label: "Yes",
        value: "1",
      },
      {
        label: "No",
        value: "0",
      },
    ],
  },
  {
    key: "UserDef3",
    label: "Loc List",
    validators: [
      {
        validate: "length",
        max: 10,
        errorMessage: "Max length is 10 characters",
      },
    ],
    type: "select",
    selectOptions: [
      {
        label: "",
        value: "",
      },
      {
        label: "APT",
        value: "APT",
      },
      {
        label: "COTTAGE",
        value: "COTTAGE",
      },
      {
        label: "DUPLEX",
        value: "DUPLEX",
      },
      {
        label: "HOUSE",
        value: "HOUSE",
      },
      {
        label: "TOWN",
        value: "TOWN",
      },
    ],
  },
  {
    key: "UserDef4",
    label: "Loc Date",
    validators: [
      {
        validate: "length",
        max: 10,
        errorMessage: "Max length is 10 characters",
      },
    ],
    type: [
      "date",
      {
        locale: "en",
        displayFormat: "M/d/yyyy",
        outputFormat: "yyyy/M/d",
      },
    ],
  },
  {
    key: "UserDef5",
    label: "Loc Num",
    validators: [
      {
        validate: "length",
        max: 10,
        errorMessage: "Max length is 10 characters",
      },
      {
        validate: "regex_match",
        regex: "^(-)?[0-9]*(\\.[0-9]*)?$",
        errorMessage: "Invalid number",
      },
    ],
    type: "string",
  },
  {
    key: "UserDef7",
    label: "Auto Num",
    validators: [
      {
        validate: "regex_match",
        regex: "^.{0,10}$",
        errorMessage: "Max length is 10 characters",
      },
    ],
    type: "select",
    selectOptions: [
      {
        label: "",
        value: "",
      },
      {
        label: "BANK",
        value: "BANK",
      },
      {
        label: "POWER",
        value: "POWER",
      },
    ],
  },
  {
    key: "CompanyNameBillto",
    label: "Company [Billto]",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 35,
        errorMessage: "Max length is 35 characters",
      },
    ],
  },
  {
    key: "FNameBillto",
    label: "First Name [Billto]",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 15,
        errorMessage: "Max length is 15 characters",
      },
    ],
  },
  {
    key: "LNameBillto",
    label: "Last Name [Billto]",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 20,
        errorMessage: "Max length is 20 characters",
      },
    ],
  },
  {
    key: "TitleBillto",
    label: "Title [Billto]",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 12,
        errorMessage: "Max length is 12 characters",
      },
    ],
  },
  {
    key: "Address1Billto",
    label: "Address 1 [Billto]",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 30,
        errorMessage: "Max length is 30 characters",
      },
    ],
  },
  {
    key: "Address2Billto",
    label: "Address 2 [Billto]",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 30,
        errorMessage: "Max length is 30 characters",
      },
    ],
  },
  {
    key: "ZipBillto",
    label: "Zip Code [Billto]",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 10,
        errorMessage: "Max length is 10 characters",
      },
    ],
  },
  {
    key: "CityBillto",
    label: "City [Billto]",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 20,
        errorMessage: "Max length is 20 characters",
      },
    ],
  },
  {
    key: "StateBillto",
    label: "State [Billto]",
    type: "select",
    selectOptions: [
      {
        label: "",
        value: "",
      },
      {
        label: "AB",
        value: "AB",
      },
      {
        label: "CA",
        value: "CA",
      },
      {
        label: "NB",
        value: "NB",
      },
      {
        label: "NJ",
        value: "NJ",
      },
      {
        label: "NY",
        value: "NY",
      },
      {
        label: "PA",
        value: "PA",
      },
      {
        label: "QC",
        value: "QC",
      },
      {
        label: "SK",
        value: "SK",
      },
      {
        label: "TX",
        value: "TX",
      },
      {
        label: "VA",
        value: "VA",
      },
    ],
    validators: [],
  },
  {
    key: "EMailBillto",
    label: "E-Mail [Billto]",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 200,
        errorMessage: "Max length is 200 characters",
      },
    ],
  },
  {
    key: "PhoneBillto",
    label: "Phone [Billto]",
    type: "string",
    validators: [
      {
        validate: "regex_match",
        regex: "^.{0,12}$",
        errorMessage: "Max length is 12 characters",
      },
      {
        validate: "regex_match",
        regex: "^\\d{3}-\\d{3}-\\d{4}$",
        errorMessage: "Phone number format ###-###-#### is required",
      },
    ],
  },
  {
    key: "PhoneExtBillto",
    label: "Phone Ext [Billto]",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 5,
        errorMessage: "Max length is 5 characters",
      },
    ],
  },
  {
    key: "AltPhoneBillto",
    label: "Alt Phone [Billto]",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 12,
        errorMessage: "Max length is 12 characters",
      },
      {
        validate: "regex_match",
        regex: "^\\d{3}-\\d{3}-\\d{4}$",
        errorMessage: "Phone number format ###-###-#### is required",
      },
    ],
  },
  {
    key: "AltPhoneExtBillto",
    label: "Alt Phone Ext [Billto]",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 5,
        errorMessage: "Max length is 5 characters",
      },
    ],
  },
  {
    key: "MobileBillto",
    label: "Mobile Phone [Billto]",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 12,
        errorMessage: "Max length is 12 characters",
      },
      {
        validate: "regex_match",
        regex: "^\\d{3}-\\d{3}-\\d{4}$",
        errorMessage: "Phone number format ###-###-#### is required",
      },
    ],
  },
  {
    key: "MobileExtBillto",
    label: "Mobile Phone Ext [Billto]",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 5,
        errorMessage: "Max length is 5 characters",
      },
    ],
  },
  {
    key: "FaxBillto",
    label: "Fax [Billto]",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 12,
        errorMessage: "Max length is 12 characters",
      },
      {
        validate: "regex_match",
        regex: "^\\d{3}-\\d{3}-\\d{4}$",
        errorMessage: "Phone number format ###-###-#### is required",
      },
    ],
  },
  {
    key: "FaxExtBillto",
    label: "Fax Ext [Billto]",
    type: "string",
    validators: [
      {
        validate: "length",
        max: 5,
        errorMessage: "Max length is 5 characters",
      },
    ],
  },
  {
    key: "UserDef6",
    label: "Bill Txt[Billto]",
    validators: [
      {
        validate: "length",
        max: 10,
        errorMessage: "Max length is 10 characters",
      },
    ],
    type: "string",
  },
  {
    key: "UserDef32",
    label: "Bill Ck[Billto]",
    validators: [
      {
        validate: "length",
        max: 10,
        errorMessage: "Max length is 10 characters",
      },
    ],
    type: "select",
    selectOptions: [
      {
        label: "",
        value: "",
      },
      {
        label: "Yes",
        value: "1",
      },
      {
        label: "No",
        value: "0",
      },
    ],
  },
  {
    key: "UserDef33",
    label: "Bill List[Billto]",
    validators: [
      {
        validate: "length",
        max: 10,
        errorMessage: "Max length is 10 characters",
      },
    ],
    type: "select",
    selectOptions: [
      {
        label: "",
        value: "",
      },
      {
        label: "CASH",
        value: "CASH",
      },
      {
        label: "CREDIT",
        value: "CREDIT",
      },
      {
        label: "GOOD",
        value: "GOOD",
      },
    ],
  },
  {
    key: "UserDef34",
    label: "Bill Date[Billto]",
    validators: [
      {
        validate: "length",
        max: 10,
        errorMessage: "Max length is 10 characters",
      },
    ],
    type: [
      "date",
      {
        locale: "en",
        displayFormat: "M/d/yyyy",
        outputFormat: "yyyy/M/d",
      },
    ],
  },
  {
    key: "UserDef35",
    label: "Bill Num[Billto]",
    validators: [
      {
        validate: "length",
        max: 10,
        errorMessage: "Max length is 10 characters",
      },
      {
        validate: "regex_match",
        regex: "^(-)?[0-9]*(\\.[0-9]*)?$",
        errorMessage: "Invalid number",
      },
    ],
    type: "string",
  },
];

export const DEFAULT_FIELDS: IDeveloperField[] = [
  {
    label: "Email",
    key: "email",
    type: "email",
    validators: [
      {
        validate: "required",
      },
    ],
  },
  {
    label: "First Name",
    key: "firstName",
  },
];
