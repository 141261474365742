import React from "react";
import { renderToString } from "react-dom/server";
import tippy, { Instance as TippyInstance } from "tippy.js";
import Tooltip from "../components/Tooltip";
import {
  IDeveloperField,
  ITableMessageInternal,
  ITableMessages,
} from "../interfaces";
import { difference } from "lodash";

export default class TippyManager {
  tippys: Map<number, TippyInstance[]>;
  columnHeaderTippys: TippyInstance[];
  currentVisibleRows: number[];

  constructor() {
    this.tippys = new Map();
    this.columnHeaderTippys = [];
    this.currentVisibleRows = [];
  }

  updateTippys(tableMessages: ITableMessages, visibleRows: number[]): void {
    const rowsToRemove = difference(this.currentVisibleRows, visibleRows);

    for (const rowIndex of rowsToRemove) {
      this.removeRow(rowIndex);
    }

    for (const rowIndex of visibleRows) {
      this.removeRow(rowIndex); // table messages may have changed, so re-render all visible rows

      const rowMessages = tableMessages.get(rowIndex);
      if (rowMessages === undefined) continue;

      const toBuild: [string, ITableMessageInternal[]][] = [];
      for (const [colIndex, messages] of rowMessages) {
        toBuild.push([`${rowIndex}-${colIndex}`, messages]);
      }

      const rowTippys = this.addTippys(toBuild);
      this.tippys.set(rowIndex, rowTippys);
    }

    this.currentVisibleRows = visibleRows;
  }

  addColumnHeaderTippys(fieldSpecs: Map<number, IDeveloperField>): void {
    if (this.columnHeaderTippys.length > 0) {
      for (const tippy of this.columnHeaderTippys) {
        tippy.destroy();
      }
    }

    const tippys: [string, ITableMessageInternal[]][] = [];

    fieldSpecs.forEach((field, colIndex) => {
      if (field.description) {
        const key = `column-header-${colIndex}`;
        tippys.push([
          key,
          [
            {
              // we want this to be a column-validation message so that it gets
              // recomputed regardless of what row has changed
              type: "column-validation",
              message: field.description,
              level: "info",
            },
          ],
        ]);
      }
    });

    this.columnHeaderTippys = this.addTippys(tippys);
  }

  private removeRow(rowIndex: number) {
    if (!this.tippys.has(rowIndex)) return;

    for (const tippy of this.tippys.get(rowIndex)!) {
      tippy.destroy();
    }

    this.tippys.delete(rowIndex);
  }

  private addTippys(
    tippys: [key: string, messages: ITableMessageInternal[]][]
  ): TippyInstance[] {
    return tippys.flatMap(([key, values]) => {
      const tippyClassName = `tippy-${key}`;

      const infoMessages = values
        .filter(
          (message) =>
            message.level === "info" &&
            message?.message?.trim().length > 0 &&
            message.type !== "transform-highlight"
        )
        .map((message) => message.message);

      const errorMessages = values
        .filter(
          (message) =>
            message.level === "error" && message?.message?.trim().length > 0
        )
        .map((message) => message.message);

      const warningMessages = values
        .filter(
          (message) =>
            message.level === "warning" && message?.message?.trim().length > 0
        )
        .map((message) => message.message);

      const transformMessages = values
        .filter(
          (message) =>
            message.type === "transform-highlight" &&
            message?.message?.trim().length > 0
        )
        .map((message) => message.message);

      const tippyInstances = tippy(`.${tippyClassName}`, {
        content: renderToString(
          <Tooltip
            infoMessages={infoMessages}
            errorMessages={errorMessages}
            warningMessages={warningMessages}
            transformMessages={transformMessages}
          />
        ),
        allowHTML: true,
      });

      return tippyInstances;
    });
  }

  deleteAllTippys(): void {
    for (const tippy of this.columnHeaderTippys) {
      tippy.destroy();
    }
    this.columnHeaderTippys = [];

    for (const tippys of this.tippys.values()) {
      tippys.forEach((tippy) => tippy.destroy());
    }

    this.tippys.clear();
  }
}
