import React from "react";
import { Alert } from "../commonComponents/Alert";
import { useTranslation } from "react-i18next";

interface IAutoCreateCustomFieldsAlertProps {
  numUnmappedColumns: number;
}

export function AutoCreateCustomFieldsAlert({
  numUnmappedColumns: unmappedColumnsCount,
}: IAutoCreateCustomFieldsAlertProps) {
  const { t } = useTranslation();

  if (unmappedColumnsCount === 0) return null;

  return (
    <Alert type="warning" data-cy="auto-create-custom-fields-alert">
      {t("alert.autoCreateCustomFields", { count: unmappedColumnsCount })}
    </Alert>
  );
}
