// This file is used as the default configuration when running the frontend
// in "direct mount" mode, with `npm run start:direct_mount`

/* eslint-disable no-console, @typescript-eslint/no-unused-vars */

import { IWrapperConfig } from "./components/UploaderTestWrapper";
import { VERSION_TWO } from "./constants/constants";
import { DEFAULT_FIELDS } from "./directMountConsts";

const LICENSE_KEY = "528b243d-b053-4be1-adaf-c1418d5a5713";
// const LICENSE_KEY = "1548a39e-0a6f-4fb0-bd13-39b7207f87b4";

const standardConfig: IWrapperConfig = {
  licenseKey: LICENSE_KEY,
  fields: DEFAULT_FIELDS,
  settings: {
    importIdentifier: "test",
    version: VERSION_TWO,
    browserExcelParsing: true,
    invalidDataBehavior: "REMOVE_INVALID_ROWS",
    allowEmptySubmit: false,
  },
  user: { id: "test" },
  columnHooks: [],
  rowHooks: [],
  bulkRowHooks: [],
  stepHooks: [],
  rowDeleteHooks: [],
  resultsCallback: (data, _metadata) => {
    console.table(data);
  },
  cancelCallback: () => {
    console.log("cancelled");
  },
};

const savedSchemaConfig: IWrapperConfig = {
  licenseKey: "ae153fbd-c96f-4705-a7b3-ad792db3ef75",
  schemaName: "basic",
};

export default standardConfig;
// export default savedSchemaConfig;
