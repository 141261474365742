import * as Sentry from "@sentry/react";
import { RootState } from "../store/reducers";
import { AppThunk } from "../store/configureStore";
import {
  downloadFileToBuffer,
  downloadJson,
  uploadSavedProgressFileHelper,
  getAPIClient,
} from "../helpers/APIHelpers";
import { objectToFile } from "../helpers/CoreDataHelpers";
import {
  serializeFieldsWithSnapshots,
  deserializeFields,
  serializeSelectOptionOverrides,
  deserializeSelectOptionOverrides,
  serializeUserMessages,
  deserializeUserMessages,
  ISerializedFieldStateSnapshots,
  ISerializedSelectOptionOverrides,
  ISerializedUserMessages,
} from "../helpers/serialization";

import {
  IFieldsReducerState,
  setFieldState,
  setSnapshot,
} from "../store/reducers/fields";
import { RehydrateStage, setRehydrateStage } from "../store/reducers/modals";
import { startFromStep, pushStep } from "../store/reducers/steps";
import {
  setData,
  setUploadId,
  setOriginalFilename,
  setSelectedSheet,
  setRawDataHeaderRow,
  setTableMessages,
  setCellTransformErrors,
  setSelectOptionOverrides,
  selectHasInitialData,
  setBeforeFinishMessage,
} from "../store/reducers/coredata";
import {
  showProcessingModal,
  hideProcessingModal,
} from "../store/reducers/commonComponents";
import { selectMappedFieldInstances } from "../store/selectors";
import {
  processSelectedFile,
  parseSelectedSheet,
  setHeaderRow,
} from "../thunks/file_processing";
import { selectNeedsBulkEditStep } from "../thunks/step_navigation";
import { runValidations } from "./data_actions";
import { FullDataWithMeta, addTableMessage } from "../util/data_actions";
import { ITableMessages, ITableMessageInternal } from "../interfaces";
import { uploadCleanedFile } from "../headless/file_upload";
import { IField } from "../fields";

interface IAbstractRehydrateState<Stage extends RehydrateStage> {
  stage: Stage;
}

type WithoutStage<T extends IAbstractRehydrateState<any>> = Omit<T, "stage">;

export interface ISheetSelectionStateWithFile
  extends IAbstractRehydrateState<"SHEET_SELECTION"> {
  uploadId: string;
  hasInitialData: false;
}
export interface ISheetSelectionStateWithInitialData
  extends IAbstractRehydrateState<"SHEET_SELECTION"> {
  uploadId: string | null;
  hasInitialData: true;
}

export type ISheetSelectionState =
  | ISheetSelectionStateWithFile
  | ISheetSelectionStateWithInitialData;
export interface IHeaderSelectionStateWithFile
  extends IAbstractRehydrateState<"HEADER_SELECTION"> {
  uploadId: string;
  selectedSheetId: string;
  hasInitialData: false;
}
export interface IHeaderSelectionStateWithInitialData
  extends IAbstractRehydrateState<"HEADER_SELECTION"> {
  uploadId: string | null;
  selectedSheetId: null;
  hasInitialData: true;
}

export type IHeaderSelectionState =
  | IHeaderSelectionStateWithFile
  | IHeaderSelectionStateWithInitialData;
export interface IColumnMatchingStateWithFile
  extends IAbstractRehydrateState<"COLUMN_MATCHING"> {
  uploadId: string;
  selectedSheetId: string;
  rawDataHeaderRow: number;
  fields: ISerializedFieldStateSnapshots;
  hasInitialData: false;
}
export interface IColumnMatchingStateWithInitialData
  extends IAbstractRehydrateState<"COLUMN_MATCHING"> {
  uploadId: string | null;
  selectedSheetId: null;
  rawDataHeaderRow: number | null;
  fields: ISerializedFieldStateSnapshots;
  hasInitialData: true;
}

export type IColumnMatchingState =
  | IColumnMatchingStateWithFile
  | IColumnMatchingStateWithInitialData;
export interface IDataReviewStateWithFile
  extends IAbstractRehydrateState<"DATA_REVIEW"> {
  uploadId: string;
  selectedSheetId: string;
  rawDataHeaderRow: number;
  fields: ISerializedFieldStateSnapshots;
  savedProgressId: string;
  transformErrorCells: string[];
  userMessages: ISerializedUserMessages;
  selectOptionOverrides: ISerializedSelectOptionOverrides;
  hasInitialData: false;
  beforeFinishMessage: string | null;
}
export interface IDataReviewStateWithInitialData
  extends IAbstractRehydrateState<"DATA_REVIEW"> {
  uploadId: string | null;
  selectedSheetId: null;
  rawDataHeaderRow: number | null;
  fields: ISerializedFieldStateSnapshots;
  savedProgressId: string;
  transformErrorCells: string[];
  userMessages: ISerializedUserMessages;
  selectOptionOverrides: ISerializedSelectOptionOverrides;
  hasInitialData: true;
  beforeFinishMessage: string | null;
}
export type IDataReviewState =
  | IDataReviewStateWithFile
  | IDataReviewStateWithInitialData;

export type IRehydrateState =
  | ISheetSelectionState
  | IHeaderSelectionState
  | IColumnMatchingState
  | IDataReviewState;

export const dumpStateForRehydration = (
  stage: RehydrateStage,
  fullData?: FullDataWithMeta
): AppThunk<Promise<IRehydrateState>> => {
  return async (dispatch, getState) => {
    if (stage === "DATA_REVIEW") {
      return await dispatch(
        dumpStateForDataReview(fullData as FullDataWithMeta)
      );
    }

    let selector: (state: RootState) => IRehydrateState;

    switch (stage) {
      case "SHEET_SELECTION":
        selector = dumpStateForSheetSelection;
        break;
      case "HEADER_SELECTION":
        selector = dumpStateForHeaderSelection;
        break;
      case "COLUMN_MATCHING":
        selector = dumpStateForColumnMatching;
        break;
      default:
        throw new Error(`Unhandled rehydrate stage: ${stage}`);
    }

    return selector(getState());
  };
};

const dumpStateForSheetSelection = (state: RootState): ISheetSelectionState => {
  const hasInitialData = selectHasInitialData(state);
  const uploadId = state.coredata.uploadId;
  if (uploadId === null && !hasInitialData) {
    throw new Error("Can't dump state. Need uploadId unless initialData.");
  }
  return {
    stage: "SHEET_SELECTION",
    uploadId,
    hasInitialData,
  } as ISheetSelectionState;
};

const dumpStateForHeaderSelection = (
  state: RootState
): IHeaderSelectionState => {
  const sheetSelectionState = dumpStateForSheetSelection(state);
  const hasInitialData = selectHasInitialData(state);
  const selectedSheetId = state.coredata.selectedSheetId;

  if (
    (selectedSheetId === null && !hasInitialData) ||
    (selectedSheetId !== null && hasInitialData)
  ) {
    throw new Error(
      "Can't dump state. Need either initialData or selectedSheetId"
    );
  }

  return {
    ...sheetSelectionState,
    stage: "HEADER_SELECTION",
    selectedSheetId: state.coredata.selectedSheetId,
  } as IHeaderSelectionState;
};

const dumpStateForColumnMatching = (state: RootState): IColumnMatchingState => {
  const headerSelectionState = dumpStateForHeaderSelection(state);
  const rawDataHeaderRow = state.coredata.rawDataHeaderRow;
  const { uploadType } = state.coredata.data;

  if (rawDataHeaderRow === null && uploadType !== "INITIAL_DATA_MAPPED") {
    throw new Error(
      "Can't dump state. Need rawDataheaderRow unless using mapped initialData"
    );
  }

  return {
    ...headerSelectionState,
    stage: "COLUMN_MATCHING",
    rawDataHeaderRow: state.coredata.rawDataHeaderRow,
    fields: serializeFieldsWithSnapshots(state.fields),
  } as IColumnMatchingState;
};

let uploadProgressFile: (
  savedProgressFile: File,
  dataLength: number
) => AppThunk<Promise<{ uploadId: string }>>;
if (process.env.JS_PLATFORM === "headless") {
  uploadProgressFile = (savedProgressFile: File, dataLength: number) =>
    uploadCleanedFile(savedProgressFile, true, dataLength, null);
} else {
  uploadProgressFile = (savedProgressFile: File, dataLength: number) =>
    uploadSavedProgressFileHelper(savedProgressFile, dataLength);
}

const dumpStateForDataReview = (
  fullData: FullDataWithMeta
): AppThunk<Promise<IDataReviewState>> => {
  return async (dispatch, getState) => {
    // optimization opportunity - we don't need full data here, which includes
    // ignored and removed columns. we could filter to just mapped columns
    const savedProgressFile = objectToFile(
      fullData,
      "saved_progress.json",
      "json",
      true
    ) as File;

    const { uploadId } = await dispatch(
      uploadProgressFile(savedProgressFile, fullData.length)
    );

    const state = getState();
    const columnMatchingState = dumpStateForColumnMatching(state);

    return {
      ...columnMatchingState,
      stage: "DATA_REVIEW",
      savedProgressId: uploadId,
      transformErrorCells: [...state.coredata.transformErrorCells.values()],
      userMessages: serializeUserMessages(state.coredata.tableMessages),
      fields: serializeFieldsWithSnapshots(state.fields),
      selectOptionOverrides: serializeSelectOptionOverrides(
        state.coredata.selectOptionOverrides
      ),
      beforeFinishMessage: state.coredata.beforeFinishMessage,
    };
  };
};

export const rehydrate = (state: IRehydrateState): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    dispatch(showProcessingModal());
    dispatch(setRehydrateStage(state.stage));

    switch (state.stage) {
      case "SHEET_SELECTION":
        dispatch(startFromStep("UPLOAD"));
        break;
      case "HEADER_SELECTION":
        await dispatch(rehydrateHeaderSelection(state));
        dispatch(startFromStep("HEADER_SELECT"));
        break;
      case "COLUMN_MATCHING":
        await dispatch(rehydrateColumnMatching(state));
        dispatch(startFromStep("COLUMN_MATCH"));
        dispatch(pushStep("HEADER_SELECT"));
        break;
      case "DATA_REVIEW":
        await dispatch(rehydrateDataReview(state));
        dispatch(startFromStep("REVIEW"));
        dispatch(pushStep("HEADER_SELECT"));
        dispatch(pushStep("COLUMN_MATCH"));
        if (selectNeedsBulkEditStep(getState())) {
          dispatch(pushStep("SELECT_MATCH"));
        }
        break;
      default:
        assertNever(state);
    }

    dispatch(hideProcessingModal());
  };
};

export const processFileForRehydration = (
  state: IRehydrateState
): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    if (state.hasInitialData) {
      return;
    }
    const appState = getState();
    const api = getAPIClient(appState);
    dispatch(setUploadId(state.uploadId));

    const rawUploadMetadata = await api.getRawUploadMetadata(state.uploadId);
    dispatch(setOriginalFilename(rawUploadMetadata.filename));

    const downloadUrl = await api.getRawDownloadUrl(state.uploadId);
    const fileBuffer = await downloadFileToBuffer(downloadUrl);
    const file = new File([fileBuffer], rawUploadMetadata.filename ?? "");
    dispatch(setData({ file, uploadType: "FILE" }));
    await dispatch(processSelectedFile());
  };
};

const processSheet = (
  setFullDataAddMeta: (data: string[][]) => void
): AppThunk<Promise<void>> => {
  return async (dispatch) => {
    const { success, data } = await dispatch(parseSelectedSheet());

    if (success) {
      setFullDataAddMeta(data);
    } else {
      Sentry.captureMessage(
        "Encountered error parsing sheet during rehydration"
      );
    }
  };
};

const rehydrateHeaderSelection = (
  state: WithoutStage<IHeaderSelectionState>
): AppThunk<Promise<void>> => {
  return async (dispatch, _, { setFullDataAddMeta }) => {
    if (state.hasInitialData) {
      return;
    }
    dispatch(setSelectedSheet(state.selectedSheetId));
    await dispatch(processSheet(setFullDataAddMeta));
  };
};

const rehydrateColumnMatching = (
  state: WithoutStage<IColumnMatchingState>
): AppThunk<Promise<void>> => {
  return async (dispatch, _getState, { setFullDataAddMeta }) => {
    if (!state.hasInitialData) {
      dispatch(setSelectedSheet(state.selectedSheetId));
      dispatch(setRawDataHeaderRow(state.rawDataHeaderRow));
      await dispatch(processSheet(setFullDataAddMeta));
    } else {
      dispatch(setRawDataHeaderRow(state.rawDataHeaderRow));
    }
    dispatch(setHeaderRow(state.rawDataHeaderRow));
    dispatch(rehydrateFieldStateWithSnapshots(state.fields));
  };
};

const rehydrateFieldStateWithSnapshots = (
  fieldState: ISerializedFieldStateSnapshots
): AppThunk<IFieldsReducerState> => {
  return (dispatch) => {
    const { snapshots, ...serializedState } = fieldState;
    const currentState = deserializeFields(serializedState);

    dispatch(setFieldState(currentState));
    for (const [name, serializedState] of snapshots) {
      const deserialized = deserializeFields(serializedState);
      dispatch(setSnapshot(name, deserialized));
    }

    return currentState;
  };
};

const addTransformErrorsToUserMessages = (
  errorCells: Set<string>,
  tableMessages: ITableMessages,
  fieldInstances: Map<number, IField>
) => {
  errorCells.forEach((cellKey) => {
    const [rowIdx, colIdx] = cellKey.split(",").map((v) => parseInt(v));
    const field = fieldInstances.get(colIdx)!;

    const message: ITableMessageInternal = {
      type: "field-transform",
      level: "error",
      message: field.invalidValueMessage,
    };

    addTableMessage(tableMessages, rowIdx, colIdx, message);
  });
};

const rehydrateDataReview = (
  state: WithoutStage<IDataReviewState>
): AppThunk<Promise<void>> => {
  return async (dispatch, getState, { setFullData }) => {
    const appState = getState();
    const api = getAPIClient(appState);
    // future optimization opportunity - when rehydrating here, we download
    // both the full original file, and the full data at the time the import
    // was saved. we could just download the save data, but then the original
    // file will need to be downloaded on-demand if the user goes backwards
    // from the review step.

    await dispatch(rehydrateColumnMatching(state));
    const errorCellsSet = new Set(state.transformErrorCells);
    dispatch(setCellTransformErrors(errorCellsSet));

    const tableMessages = deserializeUserMessages(state.userMessages);
    const fieldInstances = selectMappedFieldInstances(getState());
    addTransformErrorsToUserMessages(
      errorCellsSet,
      tableMessages,
      fieldInstances
    );

    const selectOptionOverrides = deserializeSelectOptionOverrides(
      state.selectOptionOverrides
    );
    dispatch(setSelectOptionOverrides(selectOptionOverrides));

    dispatch(setTableMessages(tableMessages));

    dispatch(setBeforeFinishMessage(state.beforeFinishMessage));

    const savedDataUrl = await api.getCleanedDownloadUrl(state.savedProgressId);
    const fullData = (await downloadJson(savedDataUrl)) as FullDataWithMeta;
    setFullData(fullData);
    dispatch(runValidations(fullData));
  };
};

const assertNever = (state: never): never => {
  throw new Error(`Unknown rehydration state: ${(state as any).stage}`);
};
